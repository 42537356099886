
















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const AppModule = namespace("AppModule");
const SnackbarModule = namespace("SnackbarModule");
@Component
export default class Snackbar extends Vue {
  @AppModule.Getter
  public smallScreen!: string;
  @SnackbarModule.State
  public color!: string;
  @SnackbarModule.State
  public message!: string;
  get status() {
    return this.$store.state.SnackbarModule.status;
  }
  set status(value) {
    this.$store.commit("SnackbarModule/SET_SNACKBAR", value);
  }
}
